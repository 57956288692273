import $ from 'jquery';
import 'jquery-validation';
import { validationOptions } from '../constants/options';
import pL from 'js-regex-pl';
import GaTracking from '../core/components/ga-tracking';
import { getEnglishErrorMessageByMessageText } from '../utils/error';
export default class FormValidate {
    static init($root = $('body')) {
        $root.find('.js__form-validate').each((index, element) => {
            const $form = $(element);
            if ($form.data('validate-after-submit') && !$form.data('is-validation-init')) {
                $form.find('[type="submit"]').on('click', () => {
                    this.initValidation($form);
                    $form.data('is-validation-init', true);
                });
            }
            else {
                this.initValidation($form);
            }
            this.addValidationRules();
            $form.find('.js__validate-on-focus').on('focus', e => {
                $(e.currentTarget).valid();
            });
            $form.on('submit', () => {
                const errorDatalayer = $form.data('datalayer-on-error');
                if (!$form.valid() && errorDatalayer) {
                    const validator = $form.validate();
                    GaTracking.pushDataLayer({
                        ...errorDatalayer,
                        eventLabel: `Error - ${getEnglishErrorMessageByMessageText(validator.errorList[0]?.message)}`,
                    });
                }
                const submitDatalayer = $form.data('datalayer-on-submit');
                if (submitDatalayer) {
                    const $mainInput = $($form.data('datalayer-value-input'));
                    GaTracking.pushDataLayer({
                        ...submitDatalayer,
                        ...{ eventLabel: submitDatalayer.eventLabel.replace('%val%', $mainInput.val()) },
                    });
                }
            });
        });
    }
    static initValidation($form) {
        $form.validate({
            ...validationOptions,
            rules: $form.data('rules'),
            messages: $form.data('messages'),
            ignore: $form.data('ignore-validation') || '.js__ignore-validation',
        });
    }
    static addValidationRules() {
        $.validator.addMethod('regex', function (value, element, regexp) {
            const re = new RegExp(regexp);
            //@ts-ignore
            return this.optional(element) || re.test(value);
        });
        $.validator.addMethod('nameAndSurname', function (value, element) {
            const pattern = /^([^0-9!@#$%^&*(),.?":{}|<>]*)$/;
            //@ts-ignore
            return this.optional(element) || pattern.test(value);
        });
        $.validator.addMethod('email', function (value, element) {
            const pattern = /^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
            //@ts-ignore
            return this.optional(element) || pattern.test(value);
        });
        $.validator.addMethod('isInStock', function (value, element) {
            const $checkedInput = $(element)
                .closest('form')
                .find(`input[name="${element.name}"]:checked`);
            //@ts-ignore
            return this.optional(element) || $checkedInput.data('isInStock');
        });
        $.validator.addMethod('notEmptyDate', (value, element) => {
            return !value.match(/\p{L}/u);
        }, 'Date is required.');
        $.validator.addMethod('age', (value, element, age) => {
            const $ageValidationMessage = $('.js__age-validation-message');
            const $element = $(element);
            const date = $element.val().split('.') || [];
            const dateString = `${date[2]}-${date[1]}-${date[0]}`;
            const datePattern = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/i;
            if (!datePattern.test(dateString)) {
                $ageValidationMessage.addClass('d-none');
                return false;
            }
            const myDate = new Date(Date.parse(`${date[2]}-${date[1]}-${date[0]}`));
            const ageLimitDate = new Date();
            ageLimitDate.setFullYear(ageLimitDate.getFullYear() - age);
            const ageWarningDate = new Date();
            ageWarningDate.setFullYear(ageWarningDate.getFullYear() - ($element.data('warning-age') || age));
            const isValid = !isNaN(myDate.getTime()) && ageLimitDate > myDate;
            const isWarningVisible = !isNaN(myDate.getTime()) && ageWarningDate > myDate;
            if (isWarningVisible) {
                $ageValidationMessage.addClass('d-none');
            }
            else {
                $ageValidationMessage.removeClass('d-none');
            }
            return isValid;
        }, 'You must be at least 18 years of age.');
        $.validator.addMethod('lettersOnly', (value, element) => {
            return new RegExp(`^[${pL} ]+$`).test(value);
        }, 'Letters only please');
    }
}
