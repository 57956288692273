import $ from 'jquery';
import LazyImages from '../../../components/lazy-images';
import CheckoutMessage from '../../../components/checkout-message';
import GaTracking from '../../../core/components/ga-tracking';
import TrapFocus from '../../../core/components/trap-focus';
export default class CartPopup {
    static $popup = $('.js__cart-popup');
    static popupCloseSelector = '.js__cart-popup-close';
    static autoCloseTimeout;
    static autoCloseTime = 8000;
    static autoCloseAfterInteractionTime = 4000;
    static autoClose;
    static init() {
        this.attachInteractionEvents();
    }
    static populateAndOpenPopup(content) {
        this.autoClose = true;
        this.closePopup();
        this.$popup.html(content);
        this.$popup.find(this.popupCloseSelector).on('click', () => {
            this.closePopup();
        });
        CheckoutMessage.init(this.$popup, true);
        this.openPopup();
        GaTracking.pushDataLayer({
            event: 'popupDisplay',
            popupDisplayContent: 'Add to cart success',
        });
    }
    static disableAutoClose() {
        this.autoClose = false;
        this.stopAutoCloseTimeout();
    }
    static attachInteractionEvents() {
        this.$popup.on('mouseenter', this.onMouseEnter.bind(this));
        this.$popup.on('mouseleave', this.onMouseLeave.bind(this));
    }
    static onMouseEnter() {
        this.stopAutoCloseTimeout();
    }
    static onMouseLeave() {
        this.startAutoCloseTimeout(this.autoCloseAfterInteractionTime);
    }
    static startAutoCloseTimeout(visibleTime = this.autoCloseTime) {
        if (!this.autoClose) {
            return;
        }
        this.autoCloseTimeout = setTimeout(() => {
            this.closePopup();
        }, visibleTime);
    }
    static stopAutoCloseTimeout() {
        if (this.autoCloseTimeout) {
            clearTimeout(this.autoCloseTimeout);
        }
    }
    static openPopup() {
        LazyImages.update();
        this.$popup.addClass('cart-popup-visible');
        this.startAutoCloseTimeout();
        TrapFocus.addTabIndexInElement(this.$popup, 'cart-popup');
    }
    static closePopup() {
        this.$popup.removeClass('cart-popup-visible');
        this.stopAutoCloseTimeout();
        TrapFocus.removeTabIndexInElement(this.$popup, null, 'cart-popup');
    }
}
